import React from "react";
import styled from "styled-components";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";
import WebIcon from '@mui/icons-material/Web';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import CampaignIcon from '@mui/icons-material/Campaign';

export default function ServiceBox({icon, title, subtitle}) {
  let getIcon;

  switch (icon) {
    case "roller":
      getIcon = <WebIcon color="success" style={{ fontSize: 50 }} />;
      break;
    case "monitor":
      getIcon = <TabletMacIcon color="secondary" style={{ fontSize: 50 }} />;
      break;
    case "browser":
      getIcon = <CloudQueueIcon color="info" style={{ fontSize: 50 }} />;
      break;
    case "printer":
      getIcon = <CampaignIcon color="error" style={{ fontSize: 50 }} />;
      break;
    default:
      getIcon = <RollerIcon />;
      break;
  }


  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;